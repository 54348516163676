import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from 'react-icons/im';
import { FadeLoader } from "react-spinners"; 

const NastavnikDetalj = () =>{
    const {id} = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        Axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}?_embed&?_fields=acf&acf_format=standard`)
        .then((res)=>{
            setData(res.data);
            setLoading(false);
        })
    },[])
    // console.log(data)
    // console.log(data?.acf)
    return(
        <>
   <div class="container drugaSekcija dekanat nastavnik">
 <div class="container dekanat">
 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
    <main role="main" class="container drugaSekcija">
            <div class="row">
              <div class="col-md-4">
              {data && data._embedded && data._embedded['wp:featuredmedia'] !== undefined ?
               <img class="card-img-top nastanikSlika"
                alt="example"
                src={data && data._embedded && data?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url}
                />
            : " "
            }
                {/* <img class="card-img-top nastanikSlika" src="./media/images/ermin_bravo.jpeg" alt="Card image cap"/> */}
              </div>
              <div class="col-md-8">
              <div dangerouslySetInnerHTML={{ __html: data?.content?.rendered }} ></div>

              </div>
              
            </div>
           <div class="row">
            <div class="col-md-4">
                <h1 style={{color:"white", fontWeight:'normal', fontSize:"2rem"}}>{data?.title?.rendered}</h1>
                <p>UNIVERZITET U SARAJEVU - AKADEMIJA SCENSKIH UMJETNOSTI</p>
                <p>PREDMET: {data?.acf?.predmet}</p>
                <p>mail: {data?.acf?.email_adresa}</p>
            </div>
            <div class="col-md-4">
               <p>RASPORED KONSULTACIJA</p> 
               {data?.acf?.konsultacije ?
            data?.acf?.konsultacije.map((el)=>{    
             return (
             <>
               <p>{el.dan}  {el.vrijeme}</p>
                </>
             )
            })
            : ""
            }
            </div>
            <div class="col-md-4">
                {/* <div class="p-2 bd-highlight"> <img src="./media/images/ikone.png" class="img-fluid" alt="..."/>
             </div> */}

           </div>
    
            <hr/>  
            </div>                     
        </main>
          )}
        </div>
        </div>
        </>
    )
}

export default NastavnikDetalj;