import React, { useState, useEffect } from "react";
// import './style.css';
import {useParams} from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FadeLoader } from "react-spinners"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const PageDetail = () => {
    const {id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(() => {
        axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}`)
            .then((res) => {
                // console.log(res)
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            })
    }, [])

    // Proverite da li su podaci učitani pre nego što pristupite svojstvima
    
    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    const naslov = storedLanguage === 'en' ? data?.acf?.prevod_nasova || "": data?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? data?.acf?.prevod_teksta || "" : data?.content?.rendered || "";

    const prikaziOpis = parse(opis, {
        replace: (domNode) => {
            if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.endsWith('.pdf')) {
                return (
                    <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                        {domNode.children[0] && domNode.children[0].data}
                    </a>
                );
            }
        },
    });
    
    return (
        <div className="container drugaSekcija dekanat">
            <div className="container dekanat">
                 {/* Prikazuje spinner dok se podaci učitavaju */}
                 <h1>{parse(naslov)}</h1>
                 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
                <div className="container drugaSekcija">
                    {/* <h1>{parse(naslov)}</h1> */}
                    <div className="d-flex bd-highlight">
                        <div className="p-2 flex-fill bd-highlight">
                        {prikaziOpis}
                        </div>
                    </div>
                </div>
                  )}
            </div>
        </div>
    );
}

export default PageDetail;
