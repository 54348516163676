import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: 'bs', // Zadani jezik
    resources: {
      en: {
        translation: {
          aboutus: 'About us',
          home: 'Home',
          deanery: 'Deanery',
          departments:"Departments",
          teachers: 'Academic staff',
          documents:"Documents",
          international:"International",
        //   bio: 'Biography',
        //   gallery: 'Gallery',
          news: 'News',
          readMore:'Read more',
        //   contact: 'Contact',
        //   contactme:'Contact me',
        //   message:'Message',
        //   send:"Send",
        //   whatis:"What is?",
        //   pogresanOdg:"Wrong answer!",
        //   loadmore:"Load More",
        //   english: 'English',
        //   response:"Response",
        //   bosnian: 'Bosnian',
        //   name:'Name',
        },
      },
      bs: {
        translation: {
          aboutus: 'O akademiji',
          home: 'Početna',
          deanery: 'Dekanat',
          departments:"Odsjeci",
          teachers: 'Akademsko osoblje',
          documents:"Propisi i akti",
          international:"Međunarodna saradnja",
          news: 'Novosti',
          readMore:'Pročitaj više',
        //   contact: 'Kontakt',
        //   contactme:'Kontaktirajte me',
        //   whatis:"Koliko je?",
        //   send:'Pošalji',
        //   response:"Odgovor",
        //   name:'Ime',
        //   pogresanOdg:"Odgovor nije tačan!",
        //   message:'Poruka',
        //   loadmore:"Učitaj više",
        //   english: 'Engleski',
        //   bosnian: 'Bosanski'
        },
      },
      // Dodajte više jezika i prijevoda po potrebi
    },
  });

export default i18n;
