import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from "react-spinners"; 

const Vass = () => {
  const [docenti, setDocenti] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Funkcija za dohvat svih stranica sa paginacijom.
    async function dohvatiSveStranice() {
      let stranice = [];
      let stranica = 1;
      let ukupnoStranica = Infinity; // Postavite na veliki broj, kako biste dobili sve stranice.

      while (stranica <= ukupnoStranica) {
        const response = await axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages?categories=17&_embed&page=${stranica}`);

        if (response.data.length === 0) {
          break; // Nema više stranica.
        }

        stranice = stranice.concat(response.data);
        ukupnoStranica = response.headers['x-wp-totalpages']; // Ukupan broj stranica.

        stranica++;
      }

      return stranice;
    }

    // Poziv funkcije za dohvat svih stranica.
    dohvatiSveStranice()
      .then((stranice) => {
        setDocenti(stranice);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Greška prilikom dohvata stranica:', error);
      });
  }, []);

  const sortiraniDocenti = docenti.sort(function (a, b) {
    if (a.title.rendered < b.title.rendered) {
      return -1;
    }
    if (a.title.rendered > b.title.rendered) {
      return 1;
    }
    return 0;
  });

  const { id } = useParams();

  return (
    <div className="container drugaSekcija dekanat">
      <div className="container dekanat">
        <div className="container drugaSekcija">
          <h4>SPISAK ZAPOSLENIKA | VIŠI ASISTENTI</h4>
          {loading ? (
            <div
              className="text-center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
              }}
            >
              <FadeLoader color={"#123abc"} loading={loading} size={100} />
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
              {docenti?.map((el) => {
                return (
                  <div className="col mb-4" key={el.id} style={{ width: "15%" }}>
                    <Link to={`/viši-asistenti/${el.id}`} className="card-link">
                      <div className="card" style={{ backgroundColor: "unset", border: 0 }}>
                        {el && el._embedded && el._embedded['wp:featuredmedia'] !== undefined ? (
                          <img
                            className="card-img-top img-fluid img-thumbnail profe"
                            alt="example"
                            style={{ width: 150, height: 150, objectFit: 'cover', objectPosition: 'center 10%' }}
                            src={el._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url}
                          />
                        ) : (
                          ' '
                        )}
                        <div className="card-body" style={{ padding: 0, paddingLeft: 2 }}>
                          <p style={{ fontSize: 13 }}>
                            {el?.acf?.titula} {el?.title?.rendered}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vass;
