import React from 'react'
// import './Footer.css';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Box, Button, TextField } from '@mui/material';
const Footer = () => {
  return (
    <div className="container footer">
      <footer className="d-flex bd-highlight">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} className='noMobilna'>
            <img src="./media/images/70.png" className="img-fluid" alt="..." width="150" />
          </Grid>
          <Grid item xs={12} md={1} className='noMobilna'>
            <img src="./media/images/logo_unsa.svg" className="img-fluid" alt="..." width="150" />
          </Grid>
          <Grid item xs={12} md={2}>
            <h1 className="footerNaslov">Pratite nas</h1>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight"> 
              <Link to="https://www.facebook.com/asu.unsa.ba"><FontAwesomeIcon icon={faFacebook} size="3x" style={{marginRight:10}}/></Link>
              <Link to="https://www.instagram.com/asu_unsa/"><FontAwesomeIcon icon={faInstagram} size="3x" style={{marginRight:10}}/></Link>
              <Link to="https://www.youtube.com/user/ASUstudentskiradovi?feature=watch"><FontAwesomeIcon icon={faYoutube} size="3x" style={{marginRight:10}}/></Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <h1 className="footerNaslov">Pišite nam</h1>
            <p className="footer-info">UNIVERZITET U SARAJEVU - AKADEMIJA SCENSKIH UMJETNOSTI</p>
            <p className="footer-info">Tel: + 387 33 553 285 | Fax:+ 387 33 553 285</p>
            <p className="footer-info">Obala Kulina bana 11 | 7100 Sarajevo</p>
            <p className="footer-info">mail: asu@asu.unsa.ba</p>
          </Grid>
          <Grid item xs={12} md={3}>
            <h1 className="footerNaslov">Pretplatite se</h1>
            <div class="modulecontent">



            <Box id="mc_embed_signup">
      <form
        action="https://unsa.us10.list-manage.com/subscribe/post?u=fd3bf99646e11bdc9a04e4425&amp;id=bc04816a53"
        className="validate"
        id="mc-embedded-subscribe-form"
        method="post"
        name="mc-embedded-subscribe-form"
        noValidate
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          {/* <h2>Pretplatite se na Newsletter</h2> */}
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email</label>
            <TextField
              className="required email"
              id="mce-EMAIL"
              name="EMAIL"
              type="email"
              defaultValue=""
              style={{maxHeight:"50px !important"}}
            />
             <Button
              className="button"
              id="mc-embedded-subscribe"
              name="subscribe"
              type="submit"
              variant="contained"
              color="primary"
              style={{
                backgroundColor:  "black" ,
                color:  "white" ,
                // display: 'block', marginBottom: "5px",width: "100%", border: "1px solid black", 
              }}
            >
              Pretplati se
            </Button>
          </div>
          {/* <div className="clear" id="mce-responses">
            <div className="response" id="mce-error-response" style={{ display: "none" }}>
              &nbsp;
            </div>
            <div className="response" id="mce-success-response" style={{ display: "none" }}>
              &nbsp;
            </div>
          </div> */}
          {/* <div aria-hidden="true">
            <input name="b_fd3bf99646e11bdc9a04e4425_bc04816a53" tabIndex="-1" type="text" value="" />
          </div> */}
          {/* <div className="clear">
            <Button
              className="button"
              id="mc-embedded-subscribe"
              name="subscribe"
              type="submit"
              variant="contained"
              color="primary"
            >
              Pretplati se
            </Button>
          </div> */}
        </div>
      </form>
    </Box>
</div>
          </Grid>
        </Grid>
      </footer>
      <div className="footerCopy">
        <p>Copyright © Univerzitet u Sarajevu - Akademija scenskih umjetnosti 2023 | Sva prava su zadržana</p>
      </div>
    </div>
  )
}

export default Footer;
