import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import parse from 'html-react-parser';
import {Link, useParams} from 'react-router-dom';
import { ImFilePdf, ImImage, ImFileWord, ImFileExcel } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FadeLoader } from "react-spinners"; 
// import { BsFileExcel } from 'react-icons/bs';

const OdsijekDetalj = () =>{
    const {id} = useParams();
    const [odsjek, setOdsjek] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('selectedLanguage');

    useEffect(()=>{
        Axios.get(`https://asu.forward.ba/wp-json/wp/v2/pages/${id}?_embed`)
        .then((res)=>{
            setOdsjek(res.data);
            setLoading(false);
        })
    },[])

  //   if (odsjek && odsjek._embedded && odsjek._embedded['wp:featuredmedia'] !== undefined) {
  //     console.log(odsjek._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url);
  // } else {
  //     console.log(" ");
  // }

    // console.log(id)
    // console.log(odsjek?.acf)
    const naslov = storedLanguage === 'en' ? odsjek?.acf?.prevod_nasova : odsjek?.title?.rendered || "";
    const opis = storedLanguage === 'en' ? odsjek?.acf?.prevod_teksta : odsjek?.content?.rendered || "";


    const prikaziOpis = parse(opis, {
      replace: (domNode) => {
          if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.endsWith('.pdf')) {
              return (
                  <a href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                      {domNode.children[0] && domNode.children[0].data}
                  </a>
              );
          }
      },
  });
    return(
        <>
         <main role="main">
         {/* <div class="row hoverStyle oodsjekDetalj"> */}
         {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
         <div 
         class="row hoverStyle oodsjekDetalj" 
         style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + odsjek && odsjek._embedded && odsjek?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url})`,
          backgroundRepeat: 'no-repeat',
      }}
            >
          <div class="col drugi odsjekDetalj">
          {/* <img src="./media/images/sekcija2.jpeg" class="img-fluid over" alt="..."/> */}
          <div class="card upisFront ">
        <div class="card-body">
          <h1 class="card-title">{parse(naslov)}</h1>
         {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
        
            <div >{prikaziOpis}</div>
           {/* {odsjek && odsjek._embedded && odsjek._embedded['wp:featuredmedia'] !== undefined ?
               <img class="img-fluid img-thumbnail"
                alt="example"
                src={odsjek && odsjek._embedded && odsjek?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url}
                />
            : " "
            } */}
        
        {/* {odsjek?.acf?.dokumenti ?
  odsjek?.acf?.dokumenti.map((el) => {
    if (el.dokument.subtype === "pdf") {
      return (
        <li key={el.naziv_dokumenta}>
          <a href={el?.dokument?.url}><ImFilePdf size="25" /></a>
          {" "}{el?.naziv_dokumenta}
        </li>
      );
    } else if (el.dokument.subtype === "jpeg" || el.dokument.subtype === "jpg" || el.dokument.subtype === "png") {
      return (
        <li key={el.naziv_dokumenta}>
          <a href={el?.dokument?.url}><ImImage size="25" /></a>
          {" "}{el?.naziv_dokumenta}
        </li>
      );
    } else if (el.dokument.subtype === "docx" || el.dokument.subtype === "doc") {
      return (
        <li key={el.naziv_dokumenta}>
          <a href={el?.dokument?.url}><ImFileWord size="25" /></a>
          {" "}{el?.naziv_dokumenta}
        </li>
      );
    } else if (el.dokument.subtype === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return (
        <li key={el.naziv_dokumenta}>
          <a href={el?.dokument?.url}><ImFileExcel size="25" /></a>
          {" "}{el?.naziv_dokumenta}
        </li>
      );
    } else {
      return (
        <li key={el.naziv_dokumenta}>Uhh</li>
      );
    }
  })
  : ""
} */}

            
        
        </div>
      </div>
          </div>
          </div>
              )}
        </main>
        </>
    )
}

export default OdsijekDetalj;