import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom';
import Axios from 'axios'
import { FadeLoader } from "react-spinners"; 

const NovostDetalj = () =>{
    const {id} = useParams();
    const [novost, setNovost] = useState({});
    const [loading, setLoading] = useState(true);
    // console.log(id)

    useEffect(()=>{
        Axios.get(`https://asu.forward.ba/wp-json/wp/v2/posts/${id}?_embed`)
        .then((res)=>{
            setNovost(res.data);
            setLoading(false);
        })
    },[])
    // console.log(novost)
    return(
        <>
   <div class="container drugaSekcija dekanat nastavnik">
 <div class="container dekanat">
 {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
    <main role="main" class="container drugaSekcija">
            <div class="row">
            <h3>{novost?.title?.rendered}</h3>
                <div dangerouslySetInnerHTML={{ __html: novost?.content?.rendered }} ></div>
           {novost && novost._embedded && novost._embedded['wp:featuredmedia'] !== undefined ?
               <img class="img-fluid img-thumbnail"
                alt="example"
                src={novost && novost._embedded && novost?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.medium?.source_url}
                />
            : " "
            }
            
            </div>               
        </main>
            )}
        </div>
        </div>
        </>
    )
}

export default NovostDetalj;