import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import { FadeLoader } from "react-spinners"; 

const Odsjeci = () =>{

    const [odsjeci, setOdsjeci] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        Axios.get("https://asu.forward.ba/wp-json/wp/v2/pages?categories=3&_embed")
        .then((res)=>{
            setOdsjeci(res.data)
            setLoading(false);
        })
    },[])
    // const {id} = useParams();
    // console.log(odsjeci)
    function truncateString(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      }
      return str.slice(0, maxLength) + '...';
    }
    
    // Funkcija za poređenje objekata po ID-u
    function compareById(a, b) {
      return a.id - b.id;
    }

    const poredani = odsjeci.sort(compareById);
    // const poredani = sortObjectsById(odsjeci)
    // console.log(poredani)
    return(
        <>
         <main role="main" class="container odjseciBox">
         {loading ? (
                        <div
                        className="text-center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
                        }}
                      >
                        <FadeLoader color={"#123abc"} loading={loading} size={100} />
                      </div>
                    ) : (
            <div class="row odsjeci">
                {poredani.map((el)=>{
                return <div class="card odsjek">
                    {el && el._embedded && el._embedded['wp:featuredmedia'] !== undefined ?
                    <Link to={`/odsjeci/${el.id}`} class="card-link">
                      <img 
                            class="card-img-top"
                            alt="Slika"
                            style={{maxHeight:360}}
                            src={el && el._embedded && el?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url}
                        />
                      </Link>
                        
                    : " "
                    }
                    {/* <img class="card-img-top" src="./media/images/gluma.jpeg" alt="Card image cap"/> */}
                    <div class="card-body odsjekCard">
                      <h1 class="card-title odjseciTitle"><Link to={`/odsjeci/${el.id}`} class="card-link">{el.title.rendered}</Link></h1>
                      <div class="card-text" dangerouslySetInnerHTML={{ __html: truncateString(el.content.rendered, 500) }} ></div>

                      {/* <Link to={`/odsjeci/${el.id}`} class="card-link">Procitaj više...</Link> */}
    
                    </div>
                
                  </div>
               }) }
               
            </div>
              )}
      
      

    
  </main>
        </>
    )
}

export default Odsjeci;